import React from "react";

const Hello = () => {
  return (
    <div>
      <h1>An update from Sunny!</h1>
    </div>
  );
};

export default Hello;
