import React from "react";
import ReloadPrompt from "./ReloadPrompt";
import Hello from "./Hello";
import World from "./World";

function App() {
  return (
    <div>
      <Hello />
      <World />
      <ReloadPrompt />
    </div>
  );
}

export default App;
