import React from "react";
import "./ReloadPrompt.css";
import { useRegisterSW } from "virtual:pwa-register/react";

function ReloadPrompt() {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegistered(r) {
      console.log("SW Registered: " + r);
      if (r) {
        scheduleUpdateCheck(r);
      }
    },
  });

  const [updateAvailable, setUpdateAvailable] = React.useState(false);

  const scheduleUpdateCheck = React.useCallback(
    (registration) => {
      const checkForUpdates = () => {
        console.log(
          "Checking for updates...",
          new Date().toLocaleString("en-SG", { timeZone: "Asia/Singapore" })
        );
        registration.update().then((hasUpdate) => {
          if (hasUpdate) {
            setUpdateAvailable(true);
          }
        });
      };

      const scheduleNextCheck = () => {
        const now = new Date();
        const sgtNow = new Date(
          now.toLocaleString("en-US", { timeZone: "Asia/Singapore" })
        );
        const targetTime = new Date(
          sgtNow.getFullYear(),
          sgtNow.getMonth(),
          sgtNow.getDate(),
          14,
          30,
          0
        ); // 2:30 PM SGT
        if (sgtNow > targetTime) {
          targetTime.setDate(targetTime.getDate() + 1); // If it's past 2:10 PM SGT, schedule for next day
        }
        const timeUntilCheck = targetTime.getTime() - sgtNow.getTime();
        setTimeout(() => {
          checkForUpdates();
          scheduleNextCheck(); // Schedule the next check after this one completes
        }, timeUntilCheck);
      };

      scheduleNextCheck();
    },
    [setUpdateAvailable]
  );

  const handleDismiss = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
    setUpdateAvailable(false);
  };

  const handleReload = () => {
    setNeedRefresh(true);
    updateServiceWorker(true);
  };

  if (!offlineReady && !updateAvailable && !needRefresh) return null;

  return (
    <div className="ReloadPrompt-container">
      <div className="ReloadPrompt-toast">
        <div className="ReloadPrompt-message">
          {offlineReady ? (
            <span>App ready to work offline</span>
          ) : updateAvailable ? (
            <span>
              New content available, click on reload button to update.
            </span>
          ) : (
            <span>Updating to the latest version...</span>
          )}
        </div>
        <div className="ReloadPrompt-buttons">
          {updateAvailable && (
            <button
              className="ReloadPrompt-toast-button"
              onClick={handleReload}
            >
              Reload
            </button>
          )}
          <button className="ReloadPrompt-toast-button" onClick={handleDismiss}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReloadPrompt;
